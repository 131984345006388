import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from './components/Pages/Auth/Login'
import Dashboard from './components/Pages/Dashboard'
import Protected from './components/Pages/Other Pages/Protected'
import About from './components/Pages/About'
import Contact from './components/Pages/Contact'
import EnrollmentReports from './components/Pages/Reports/EnrollmentReports'
import RedemptionReport from './components/Pages/Reports/RedemptionReport'
import Registration from './components/Pages/Registration'
import Forgot from './components/Pages/Auth/Forgot'
import "primereact/resources/themes/lara-light-indigo/theme.css";
import FAQ from './components/Pages/Other Pages/FAQ'
import TNC from './components/Pages/Other Pages/TNC'
import Page_404 from './components/Pages/Other Pages/Page_404'
import FaqUppercrest from './components/Pages/Other Pages/upper crest tnc & faq/FaqUpperCrest'
import TncUppercrest from './components/Pages/Other Pages/upper crest tnc & faq/TncUpperCrest'
export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />}></Route>
          <Route path='/about' element={<About />}></Route>
          <Route path='/contact' element={<Contact />}></Route>
          <Route path='/forgot' element={<Forgot />}></Route>
          <Route path='/app/faq' element={<FAQ />}></Route>
          <Route path='/app/tnc' element={<TNC />}></Route>
          <Route path='/app/uppercrest_faq' element={<FaqUppercrest />}></Route>
          <Route path='/app/uppercrest_tnc' element={<TncUppercrest />}></Route>
          <Route path='/dashboard' element={<Protected Cmp={Dashboard} requiredRoles={['SALES', 'HO', 'ADMIN', 'RO', 'RM']} />}></Route >
          <Route path='/enrollment_report' element={<Protected Cmp={EnrollmentReports} requiredRoles={['SALES', 'HO', 'ADMIN', 'RO', 'RM']} />}></Route >
          <Route path='/redemption_report' element={<Protected Cmp={RedemptionReport} requiredRoles={['SALES', 'HO', 'ADMIN', 'RO', 'RM']} />}></Route >
          <Route path='/registration' element={<Protected Cmp={Registration} requiredRoles={['ADMIN']} />}></Route >
          <Route path='/*' element={<Page_404 />}></Route >
        </Routes >
      </BrowserRouter >
    </>
  )
}
