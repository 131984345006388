import React from 'react'

export default function TNC() {
    return (
        <>
            <div className="terms">
                <ol>
                    <li>The Participating Company does not have any right to make representation on behalf of JK Paper Ltd. Neither this Program nor its Terms and Conditions shall be construed as a distributorship, joint venture, agency relationship, or as granting of the franchisee. </li>
                    <li>JK Paper Ltd. is nowhere making an acknowledgment on behalf of the member in endorsing the latter's products or services. Any claims made by the member to the contrary will result in the expulsion of the member from the Program. The member in the "Inner Circle Program" refers to their premium members.</li>
                    <li>The “Inner Circle” Program is available to selected members who meet specific criteria at the sole discretion of the Company. Membership is invitation-only and non-transferable.</li>
                    <li>The period of the JK Paper “Inner Circle” Program will be of 12 months from 1st August ‘2023 ‒ 31st July '2024 </li>
                    <li>Enrollment in the Inner Circle Program is solely at the Company's discretion. We may invite eligible members to become Premium Members based on various factors, including purchase history, loyalty level, and other relevant criteria. </li>
                    <li>Membership is subject to verification and may be revoked if found to be in violation of these Terms or any fraudulent activity. </li>
                    <li>The company understands that it is imperative to reward & recognize the member for the eﬀorts made to promote JK Paper Ltd. products and maintain a sizable market-share. The company in return expects the same spirit to be reciprocated by the member. </li>
                    <li>JK Paper Ltd. reserves the right to change, modify, alter, or close the "Inner Circle” Program at any point in time without giving any reason(s) and intimation(s) for the same. </li>
                    <li>JK Paper Ltd. reserves the right to disqualify any member from the Program and any other Company or agency-run program, without assigning any reason / intimation / justiﬁcation thereof to the aggrieved member or any other person.</li>
                    <li>JK Paper Ltd. reserves the right for the computation of the Reward mechanics which shall be ﬁnal, conclusive, and binding on the member and will not be liable to be disputed or questioned.  </li>
                    <li>Members do not have the Option to take CASH in lieu of any kind the rewards will be redeemed only against products/services as featured in the Rewards catalog. Redemption of Rewards/products against any outstanding payments due to any of the members is not permissible</li>
                    <li>JK Paper Ltd. or its agency is not responsible for delays in post for the claims/documentation to be received as part of the program  </li>
                    <li>JK Paper Ltd. reserves the right to cancel, change, or substitute any reward at any time without any prior notice. </li>
                    <li>The existence of any dispute regarding the reward products/services shall not constitute a claim against JK Paper Ltd. The decision of JK Paper Ltd. & its representing agency will be ﬁnal and binding. No requests and communication will be entertained in this regard thereafter. </li>
                    <li>Any reward amount/scores from the old program year will not be eligible for redemption.</li>
                    <li>Points are non-transferable, have no cash value, and cannot be exchanged or combined with points from other loyalty programs unless explicitly stated otherwise </li>
                </ol>
            </div>
        </>
    )
}
