import React, { useState, useEffect } from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import member from '../../assets/img/member.svg'
import total_sales from '../../assets/img/total_sales.svg'
import total_points from '../../assets/img/total_points.svg'
import redeemed_points from '../../assets/img/redeemed_points.svg'
import * as Constants from '../../Constants/index'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Oval from '../Pages/Loader/CircleLoade';
export default function Dashboard() {
	const navigate = useNavigate()
	const userType = localStorage.getItem('user_type')
	const [topPlumber, setTopPlumber] = useState([]);
	const [dashboard, setDashboard] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {

		let token = localStorage.getItem('token');

		//top plumbers
		if (!token) {
			navigate('/');
		} else {
			try {
				let endpoint = '';

				if (userType === 'SALES') {
					endpoint = `user/manage/userBycityId?userType=MEMBER&status=APPROVED&sortBy=totalPoints&sortAsc=false&limit=10&page=1`;
				} else if (userType === 'HO' || userType === 'ADMIN' || userType === 'RO' || userType === 'RM') {
					endpoint = `user/manage/userByparentId?userType=MEMBER&status=APPROVED&sortBy=totalPoints&sortAsc=false&limit=10&page=1`;
				}
				setLoading(true);
				axios.get(Constants.baseUrl + endpoint, {
					headers: {
						Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
						platform: Constants.pt,
						appVersion: Constants.av,
						program: Constants.program,
						company: Constants.company
					}
				})
					.then((res) => {
						if (res.status === 201 && res.data.output) {
							setLoading(false);
							setTopPlumber(res.data.output.results);

						} else {
							// swal(res.data.msg);
						}
					})

			} catch (error) {
				console.log(error)
			}
		}



		// dashboard data
		if (!token) {
			navigate('/');
		} else {
			try {
				setLoading(true);
				axios.get(Constants.baseUrl + `program/dashboard/sales`, {
					headers: {
						Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
						platform: Constants.pt,
						appVersion: Constants.av,
						program: Constants.program
					}
				})
					.then((res) => {
						if (res.status === 201) {
							if (res.data.code === 10001) {
								setDashboard(res.data.output);
							} else if (res.data.code === 12001) {
								setLoading(true);
							}

						} else {
							// swal(res.data.msg);

						}
					})

			} catch (error) {
				console.log(error)
			}
		}


	}, []);
	return (
		<>
			{loading ? <Oval /> : null}
			<Header />
			<section className="blog-area pt182">
				<div className="container-fluid">
					<div className="row mt-30">
						<div className="col-md-3 col-xs-12">
							<div className="kpibox">
								<div className="upperkpi">
									<div className="iconkpi">
										<img src={member} alt='' />
									</div>
									<div className="namekpi">
										No. of Members
									</div>
								</div>
								<div className="kpilink">
									{dashboard.members}
								</div>
							</div>
						</div>
						<div className="col-md-3 col-xs-12">
							<div className="kpibox">
								<div className="upperkpi">
									<div className="iconkpi">
										<img src={total_sales} alt='' />
									</div>
									<div className="namekpi">
										Total Sales MT

									</div>
								</div>
								<div className="kpilink">
									{dashboard.totalSalesCount}
								</div>
							</div>
						</div>

						<div className="col-md-3 col-xs-12">
							<div className="kpibox">
								<div className="upperkpi">
									<div className="iconkpi">
										<img src={total_points} alt='' />
									</div>
									<div className="namekpi">
										Total Allocated Points

									</div>
								</div>
								<div className="kpilink">
									{dashboard.pointsGiven}
								</div>
							</div>
						</div>
						<div className="col-md-3 col-xs-12">
							<div className="kpibox">
								<div className="upperkpi">
									<div className="iconkpi">
										<img src={redeemed_points} />
									</div>
									<div className="namekpi">
										Total Redeemed Points
									</div>
								</div>
								<div className="kpilink">
									{dashboard.pointsRedeemed}
								</div>
							</div>
						</div>
					</div>

					<div className="row mt-30">
						<div className="col-md-5 col-sm-5 col-xs-12 col-23">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title text-center">
										Top 10 Members
									</h3>
								</div>
								<div className="card-body">
									<table className="table table-striped  topplumber mb-0">
										<tbody>
											{topPlumber && topPlumber.map((item, index) => {
												const name = item.name.split(' ')[0].length > 6 ? item.name.split(' ')[0] : item.name.split(' ')[0];
												return (
													<>
														<tr>
															<td>{index + 1}</td>
															<td>{name}</td>
															<td>{item.totalPoints}<span>Pts.</span></td>
														</tr>
													</>
												)
											})}
										</tbody>
									</table>
								</div>
							</div>
						</div>

						<div className="col-md-7 col-sm-7 col-xs-12 col37">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title text-center">
										Member’s Count by Growth %
									</h3>
								</div>
								<div className="card-body">
									<table className="table table-striped  countbygrowth mb-0">
										<tbody>
											<tr>
												<th>Target</th>
												<th>Points base</th>
												<th>#pax.</th>
											</tr>
											<tr>
												<td>0 to 5%	</td>
												<td>50</td>
												<td>{dashboard.slab1UserCount}</td>

											</tr>
											<tr>
												<td>5% to 10%</td>
												<td>200</td>
												<td>{dashboard.slab2UserCount}</td>

											</tr>
											<tr>
												<td>10% to 15%</td>
												<td>300 </td>
												<td>{dashboard.slab3UserCount}</td>
											</tr>
											<tr>
												<td>15%</td>
												<td>400</td>
												<td>{dashboard.slab4UserCount}</td>
											</tr>


										</tbody>
									</table>
								</div>
							</div>
						</div>

						{/* <div className="col-md-5 col-sm-12 col-xs-12 col40">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title text-center">
										Product Category Performance
									</h3>
								</div>
								<div className="card-body">
									<Chart />
								</div>
							</div>
						</div> */}

					</div>
				</div>
			</section>
			<Footer />
		</>
	)
}
