import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import contactus from '../../assets/img/contactus.svg'
import email from '../../assets/img/email.svg'
import { Link } from 'react-router-dom'
export default function Contact() {

    return (
        <>
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#Section1" role="tab" data-toggle="tab">Contact Us  </a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <figure className="infodetail">
                                                    <figcaption>
                                                        <h4>Program  Center</h4>
                                                        <address>
                                                            C/o Evolve Brands Pvt Ltd
                                                            <br />
                                                            249 G, AIHP Tower,
                                                            Level 2, Wing A,<br />
                                                            Udyog Vihar Phase 4,
                                                            Gurugram,122015
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-md-4">
                                                <figure className="infodetail">

                                                    <figcaption>
                                                        <h4>Helpline Number </h4>
                                                        <address>
                                                            <img src={contactus} alt='' />  0124 4830989
                                                            <br /> <img src={contactus} style={{ visibility: 'hidden' }} />  09:30AM to 5:30PM (Mon-Fri)<br />
                                                        </address>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                            <div className="col-md-4">
                                                <figure className="infodetail">
                                                    <h4>Email</h4>
                                                    <figcaption>
                                                        <h4 className="mail"><Link to="mailto:info@jkpinnercircle.com"> <img src={email} alt='' />  info@jkpinnercircle.com</Link></h4>
                                                    </figcaption>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
