import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function FaqUpperCrest() {
    return (
        <>
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <Accordion activeIndex={0}>
                                                        <AccordionTab header="1. What is the Upper Crest Program?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>The <b />The Upper Crest is an exclusive empiric rewarding program designed to offer exceptional benefits and privileges to our most loyal members. It goes beyond traditional loyalty programs to provide personalized experiences.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="2. Who are the participants?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Our top-notch members who are involved in the packaging board business, manufactured by JK Paper Ltd.
                                                                All qualifying SKUs of Unit CPM – Songadh are eligible for this program

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="3. What benefits do I get as a Program member?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Your <b />Premium members enjoy a range of benefits, such as:
                                                                <li>Exclusive access to engaging activities and services or features.</li>
                                                                <li> Personalized offers tailored to your preferences.</li>
                                                                <li> member support with dedicated assistance.</li>
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="4. Is there a membership fee for the Program?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>No, this is a complimentary benefit and service for JK Paper’s high-tier members.


                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="5.How do I unlock the privileged activities in the Program?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Member can unlock the privilege activities by achieving the prefix slab by JK Paper.

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="6.Can I use my privilege activity repeatedly after unlocking the activity in the Program?
">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>No, members may access the privileged activities for one time only.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="7. Are these activities for a valid period of time?
">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Yes, activity must be availed within a year timeframe after unlocking
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="8. How do I get to know about unlocking my privileges in the Program?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Members will get a notification call from our program Centre and can also check the Upper Crest app on their phone for the unlocking activity detail.

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="9.Can I exchange my privileged activities for other activities?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>There is no other option to choose another activity for exchange. But participants can give suggestions for the next year.


                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="10. Can I share my Program benefits with others?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Program benefits are non-transferable and meant exclusively for the member's use. However, family-sharing options or gifting benefits might be available in some conditions.

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="11.How can I track my Program status and Privileges?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>Upper Crest members can directly contact the Program Centre for the details and they may also check and track the status of their privilege activity in the Upper Crest app.

                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="12. How do I reach to Upper Crest program Centre?">
                                                            <p className="m-0 faq-ans">
                                                                <span></span>You can reach us on:
                                                                Mobile: 01244830989
                                                            </p>
                                                        </AccordionTab>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}
