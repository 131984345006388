import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import { Link } from 'react-router-dom'
import ResponsivePagination from 'react-responsive-pagination';
import Oval from '../Loader/CircleLoade';
import longarrow from '../../../assets/img/longarrow.svg'
import * as Constants from "../../../Constants/index"
import axios from 'axios'
import swal from 'sweetalert'
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { saveAs } from 'file-saver';
export default function EnrollmentReports() {
    const navigate = useNavigate()
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [results, setResults] = useState([])
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchRecord, setSearchRecord] = useState(null)
    const [limit] = useState('10')
    const [loading, setLoading] = useState(false);
    const [selectStatus, setSelectStatus] = useState('')



    const formattedDate = moment(fromDate).format('YYYY-MM-DD HH:mm:ss');
    const formattedDate2 = moment(toDate).format('YYYY-MM-DD 23:59:59');
    const start = formattedDate.replace(/:/g, '%3A')
    const end = formattedDate2.replace(/:/g, '%3A')

    useEffect(() => {

        if (currentPage !== 1) {
            enrollmentReportData(selectStatus);
        } else {
            enrollmentReportData('');
        }
    }, [currentPage])


    const enrollmentReportData = (userName) => {
        let token = localStorage.getItem('token');

        if (!token) {
            navigate('/');
        } else {
            const searchFromDate = fromDate === '' ? '' : `&start=${start}`
            const searchToDate = toDate === '' ? '' : `&end=${end}`
            const searchStatus = userName === '' ? '' : `&mobile=${userName}`
            if (fromDate !== '' && toDate === '') {
                swal({
                    text: 'Please select to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate === '' && toDate !== '') {
                swal({
                    text: 'Please select from date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if reason is not selected when status is 'reject'
            }
            if (fromDate !== '' && toDate !== '' && new Date(fromDate) > new Date(toDate)) {
                swal({
                    text: 'From date should be less than to date',
                    icon: 'error',
                    timer: 2000,
                    buttons: false,
                });
                return; // Return early if "from" date is greater than or equal to "to" date
            }
            try {
                setLoading(true);
                axios.get(`${Constants.baseUrl}reports/users/list?userType=MEMBER${searchStatus}${searchFromDate}${searchToDate}&sortBy=createDateTime&sortAsc=false&limit=${limit}&page=${currentPage}`, {

                    headers: {
                        Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    }
                })
                    .then((res) => {
                        if (res.status === 201) {
                            if (res.data.code === 10001 && res.data.output) {
                                setResults(res.data.output.results)
                                setTotalPages(res.data.output.totalPages);
                                setSearchRecord()
                                setLoading(false);
                            } else if (res.data.result === 'error') {
                                setLoading(false);
                                setSearchRecord(null)
                                setTotalPages(0);
                            } else {
                                swal({
                                    text: res.data.msg,
                                    icon: 'error',
                                    timer: 2000,
                                    buttons: false,
                                })
                            }
                        } else {
                            swal({
                                text: res.data.msg,
                                icon: 'error',
                                timer: 2000,
                                buttons: false,
                            })
                        }
                    })

            } catch (error) {
                console.log(error)
            }
        }
    }

    const download = () => {
        let token = localStorage.getItem('token');
        try {
            const searchFromDate = fromDate === '' ? '' : `&start=${start}`
            const searchToDate = toDate === '' ? '' : `&end=${end}`
            const searchStatus = selectStatus === '' ? '' : `&mobile=${selectStatus}`
            setLoading(true)
            axios.get(Constants.baseUrl + `reports/download/userreport?userType=MEMBER${searchStatus}${searchFromDate}${searchToDate}`, {

                headers: {
                    Authorization: `Bearer ${token}`, "Accept-Language": localStorage.getItem("langauge"),
                    appVersion: Constants.av,
                    platform: Constants.pt,
                    company: Constants.company,
                    program: Constants.program,
                },
                responseType: 'blob', // Set the responseType to 'blob'

            })
                .then((res) => {
                    if (res.status === 200) {
                        setLoading(false)
                        const fileData = new Blob([res.data], { type: 'text/csv' });
                        saveAs(fileData, 'enrollmentReport.csv');
                    } else {
                        swal({
                            text: res.data.msg,
                            // icon: 'error',
                            timer: 2000,
                            buttons: false,
                        })
                    }
                })
        } catch (error) {
            console.log(error)
        }
    }
    const handleSearchQuery = (event) => {
        // setSelectStatus(event.target.value)
        const input = event.target.value;
        if (/^\d*$/.test(input)) {
            setSelectStatus(input);
        }
        if (event.target.value === '') {
            enrollmentReportData('')
            setSearchRecord()
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            enrollmentReportData(selectStatus);
        }
    };
    return (
        <>
            {loading ? <Oval /> : null}
            <Header />
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                <li role="presentation" className="active"><a href="#Section1" role="tab" data-toggle="tab">Enrollment Report</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="row clearfix mb-15 callwell">
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="date-feilds">
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={(date) => setFromDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                id="Fromdate"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='From date'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <div className="date-feilds">
                                            <DatePicker
                                                selected={toDate}
                                                onChange={(date) => setToDate(date)}
                                                dateFormat="dd-MM-yyyy"
                                                className="frmcntrl"
                                                id="todate"
                                                showYearDropdown
                                                showMonthDropdown
                                                autoComplete="off"
                                                scrollableYearDropdown
                                                maxDate={new Date()}
                                                onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                                                onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                                                onClick={(e) => e.preventDefault()} // Preven
                                                placeholderText='To date'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-sm-12 col-xs-12">
                                        <input className="frmcntrl" placeholder="Mobile no." type="text" maxLength='10' value={selectStatus} onChange={handleSearchQuery} onKeyDown={handleKeyDown} />
                                    </div>
                                    <div className="col-md-3 col-sm-3 col-xs-12">
                                        <button type="button" className="btn login_white_btn"
                                            onClick={() => enrollmentReportData(selectStatus)} > Search <img src={longarrow} alt='' /></button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        {searchRecord === null ? '' :
                                            <Link className="btn login_btn fa fa-file-excel-o pull-right mt-10 mb-10" to="#" onClick={download}> Export</Link>}
                                        <div className="carrd1">
                                            <div className="card-body table-responsive">
                                                <table className="table table-bordered mytable mb_01">
                                                    <thead>
                                                        <tr>
                                                            <th>S.No.</th>
                                                            <th>Firm Name </th>
                                                            <th>Owner Name</th>
                                                            <th>Owner DOB </th>
                                                            <th>House / Flat / Block No.</th>
                                                            <th>Street / Colony / Locality Name</th>
                                                            <th>Landmark</th>
                                                            <th>Owner City </th>
                                                            <th>Owner District </th>
                                                            <th>Owner State</th>
                                                            <th>Owner Pin</th>
                                                            <th>Owner Contact </th>
                                                            <th>Owner Email </th>
                                                            <th>Firm SAP Code</th>
                                                            <th>House / Flat / Block No.</th>
                                                            <th>Street / Colony / Locality Name</th>
                                                            <th>Landmark</th>
                                                            <th>Professional City</th>
                                                            <th>Professional District</th>
                                                            <th>Professional State</th>
                                                            <th>Professional Pin Code </th>
                                                            <th>GST No. </th>
                                                            <th>Pan Number </th>
                                                            <th>Spoc Name </th>
                                                            <th>Spoc Contact</th>
                                                            <th>Spoc Email ID</th>
                                                            <th>Sales MT</th>
                                                            <th>JK Sales Member Name</th>
                                                            <th>Member Contact	</th>
                                                            <th>Member Email ID</th>
                                                            <th>Zone/State	</th>
                                                            <th>Total Points</th>
                                                            <th>Balance Point</th>
                                                            <th>Redeem Point</th>
                                                            <th>Sister Concern Name 1</th>
                                                            <th>Sister Concern Name 2</th>
                                                            <th>Sister Concern Name 3</th>
                                                            <th>Sister Concern Name 4</th>
                                                            <th>Sister Concern Name 5</th>
                                                            <th>Sister Concern Name 6</th>
                                                            <th>Sister Concern Name 7</th>
                                                            <th>Sister Concern Name 8</th>
                                                            <th>Sister Concern Name 9</th>
                                                        </tr>
                                                    </thead>
                                                    {searchRecord === null ?
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan="12" style={{ textAlign: 'center', fontSize: '16px' }}>No record found!</td>
                                                            </tr>
                                                        </tbody> :
                                                        <tbody>
                                                            {results && results.map((item, index) => {
                                                                const serialNumber = (currentPage - 1) * limit + index + 1;
                                                                // const formattedDate = moment(item.createDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY');
                                                                const sisterConcern1 = item.sisterConcern ? item.sisterConcern.sister1 : ''
                                                                const sisterConcern2 = item.sisterConcern ? item.sisterConcern.sister2 : ''
                                                                const sisterConcern3 = item.sisterConcern ? item.sisterConcern.sister3 : ''
                                                                const sisterConcern4 = item.sisterConcern ? item.sisterConcern.sister4 : ''
                                                                const sisterConcern5 = item.sisterConcern ? item.sisterConcern.sister5 : ''
                                                                const sisterConcern6 = item.sisterConcern ? item.sisterConcern.sister6 : ''
                                                                const sisterConcern7 = item.sisterConcern ? item.sisterConcern.sister7 : ''
                                                                const sisterConcern8 = item.sisterConcern ? item.sisterConcern.sister8 : ''
                                                                const sisterConcern9 = item.sisterConcern ? item.sisterConcern.sister9 : ''
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <td>{serialNumber}</td>
                                                                            <td>{item.firmName}</td>
                                                                            <td>{item.name}</td>
                                                                            <td>{item.dob}</td>
                                                                            <td>{item.addressModelPerm.permAddress1}</td>
                                                                            <td>{item.addressModelPerm.permAddress2}</td>
                                                                            <td>{item.addressModelPerm.permAddress3}</td>
                                                                            <td>{item.addressModelPerm.permCity}</td>
                                                                            <td>{item.addressModelPerm.permdistrict}</td>
                                                                            <td>{item.addressModelPerm.permState}</td>
                                                                            <td>{item.addressModelPerm.permPincode}</td>
                                                                            <td>{item.mobile}</td>
                                                                            <td>{item.email}</td>
                                                                            <td>{item.firmSapCode}</td>
                                                                            <td>{item.addressWork.workAddress1}</td>
                                                                            <td>{item.addressWork.workAddress2}</td>
                                                                            <td>{item.addressWork.workAddress3}</td>
                                                                            <td>{item.addressWork.workCity}</td>
                                                                            <td>{item.addressWork.workState}</td>
                                                                            <td>{item.addressWork.workdistrict}</td>
                                                                            <td>{item.addressWork.workPincode}</td>
                                                                            <td>{item.gst}</td>
                                                                            <td>{item.userModelKYC.panNumber}</td>
                                                                            <td>{item.spocName}</td>
                                                                            <td>{item.spocMobile}</td>
                                                                            <td>{item.spocEmail}</td>
                                                                            <td>{item.totalSalesReceived}</td>
                                                                            <td>{item.parentName}</td>
                                                                            <td>{item.parentMobile}</td>
                                                                            <td>{item.parentEmail}</td>
                                                                            <td>{item.parentZone}</td>
                                                                            <td>{item.totalPoints}</td>
                                                                            <td>{item.points}</td>
                                                                            <td>{item.redeemPoints}</td>
                                                                            <td>{sisterConcern1}</td>
                                                                            <td>{sisterConcern2}</td>
                                                                            <td>{sisterConcern3}</td>
                                                                            <td>{sisterConcern4}</td>
                                                                            <td>{sisterConcern5}</td>
                                                                            <td>{sisterConcern6}</td>
                                                                            <td>{sisterConcern7}</td>
                                                                            <td>{sisterConcern8}</td>
                                                                            <td>{sisterConcern9}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })}
                                                        </tbody>}
                                                </table>
                                                {totalPages > 1 && (
                                                    <ResponsivePagination
                                                        current={currentPage}
                                                        total={totalPages}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
            <Footer />
        </>
    )
}
