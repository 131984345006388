import React, { useState } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import logo_innercircle from '../../../assets/img/logo/logo-innercircle.svg'
import phone_icon from '../../../assets/img/phone-icon.svg'
import whitearrow from '../../../assets/img/whitearrow.svg'
import bg from '../../../assets/img/innercirclebg.png'
import swal from 'sweetalert';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as Constants from '../../../Constants/index'
import { Link } from 'react-router-dom'
export default function Forgot() {
    const [mobile, setMobile] = useState('')
    const [errors, setErrors] = useState({});
    const navigate = useNavigate()

    const validateForm = () => {
        let errors = {};

        if (!mobile) {
            errors.mobile = 'User ID is required';
        } else if (!/^\d+$/.test(mobile)) {
            errors.mobile = 'User ID should be numeric';
        } else if (mobile.length !== 10) {
            errors.mobile = 'User ID should be 10 digits';
        }


        return errors;
    };

    const handleSubmit = async () => {
        setErrors({});

        const validationErrors = validateForm();

        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return; // Don't proceed with the API call if there are validation errors
        }

        try {
            await axios.post(Constants.baseUrl + 'auth/forget-password',
                {
                    mobile: mobile,
                    password: ''
                },
                {
                    headers: {
                        appVersion: Constants.av,
                        platform: Constants.pt,
                        company: Constants.company,
                        program: Constants.program,
                    },
                }
            )
                .then((res) => {
                    if (res.status === 200) {
                        if (res.data.code === 10001) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            })
                            setTimeout(() => {
                                navigate('/')
                            }, 2000);
                        } else if (res.data.code === 12002) {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });

                        } else {
                            swal({
                                text: res.data.msg,
                                buttons: false,
                                timer: 2000,
                            });
                        }
                    } else {
                        swal({
                            text: res.data.msg,
                            buttons: false,
                            timer: 2000,
                        });
                    }
                });

        } catch (error) {
            console.log(error);
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };
    return (
        <>
            <Header />
            <section className="main-page mt134">
                <div className="container">
                    <div className="slider-part" style={{ backgroundImage: `url(${bg})`, }}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6">
                                <div className="logopage">
                                    <img src={logo_innercircle} className="img-responsive" alt="spl_logo" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="loginbox">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h4>
                                                Forgot Password
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-xs-12">
                                            <div className="form-group">
                                                <img className="icn" src={phone_icon} alt='' />
                                                <input className="form-control" placeholder="Enter User ID" type="text" maxLength='10' value={mobile} onChange={(event) => {
                                                    const input = event.target.value;
                                                    if (/^\d*$/.test(input)) {
                                                        setMobile(input);
                                                    }
                                                }} onKeyDown={handleKeyDown} />
                                            </div>
                                            {errors.mobile && (
                                                <div className="error-message-login">{errors.mobile}</div>
                                            )}
                                        </div>

                                        <div className="col-md-12 col-xs-12">
                                            <button type="submit" name="button" className="btn login_buttn" onClick={handleSubmit}>Submit <img src={whitearrow} alt='' /></button>

                                            <div className="frgt"><Link to="/">Login Here</Link></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
