import React from 'react'
import Header from '../common/Header'
import Footer from '../common/Footer'
import logo_innercircle1 from '../../assets/img/logo/logo-innercircle1.svg'
export default function About() {
    return (
        <>
            <Header />
            <section className="aboutcera pt182" id="aboutfinolex">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="desktop-television-product">

                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="section-title">
                                            <h2>About Program </h2>
                                        </div>
                                        <div className="detailabt">
                                            <p>As a symbol of our premium member’s commitment to our JK Paper brand, Inner Circle offers a plethora of unique benefits that are tailored to provide members with unmatched quality and distinction. Our members can anticipate even more premium offerings and a closer bond with us as they set out on this new journey.<br />A selection of improved advantages, treats, and opportunities that are commensurate with JK Paper Premium Members' esteemed rank.<br />Being a part of Inner Circle is extremely fulfilling! After accepting the invitation to join the Inner Circle Program, members become qualified to receive special Premium Products and E-Vouchers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <figure className="abtprgrm">
                                            <img src={logo_innercircle1} alt='' />
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}
