import React from 'react'
import { Accordion, AccordionTab } from 'primereact/accordion';

export default function FAQ() {
    return (
        <>
            <section className="blog-area pt182">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 tab mb-20">
                            <ul className="nav nav-tabs" role="tablist">
                                {/* <li role="presentation" className="active"><Link to="" role="tab" data-toggle="tab">FAQ's</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                            <div className="all-single-blog rpt">
                                <div className="carrd1 requestbox">
                                    <div className="card-body pad30">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card">
                                                    <Accordion activeIndex={0}>
                                                        <AccordionTab header="Q. What is the Inner Circle Program?  ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>The Inner Circle is an exclusive empiric rewarding program designed to offer exceptional benefits and premium products and e-Vouchers to our most loyal members.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. Is there a membership fee for the Program? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>No, this is a complimentary benefit and service for JK Paper’s high-tier members.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. Who are the participants? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Our top-notch members who are involved in the packaging board business, manufactured by JK Paper Ltd. <br />
                                                                All qualifying SKUs of Unit CPM – Songadh are eligible for this program
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. How do I get the Products and Gift Vouchers in the Program? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Members can get the products and gift vouchers by achieving the prefix slab by JK Paper Ltd.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. How do I come to know my Reward Status?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>The program cell would regularly update you about your reward status in the following manner
                                                                You can check your reward status on your Mobile App notiﬁcations or on your personal scoreboard by logging in to your account on the app
                                                                SMS will be sent to your Mobile No. registered with Inner Circle on a monthly basis
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header=" Q. Can I exchange my rewarded product for E-Vouchers?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Member may choose an option whether premium products or E-Vouchers.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. Can I Exchange my reward for Cash?">
                                                            <p className="m-0">
                                                                <span>Ans:</span>No, this is a reward and recognition program and rewards can only be redeemed against a variable set of rewards (like a Credit Card Program). Rewards can be redeemed only against the Reward amount and Not Cash.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. Will the App would be IOS or Android based? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>There are both iOS & Android apps with the name ‘Inner Circle’
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. How much time does it take to get the redeemed rewards? ">
                                                            <p className="m-0">
                                                                <span>Ans:</span>Once the reward is claimed, Inner Circle Program Center will give a validation call for product & address conﬁrmation & after this, within 4-6 weeks you'll receive the same.
                                                            </p>
                                                        </AccordionTab>
                                                        <AccordionTab header="Q. How can I track my reward status?">
                                                            <p className="m-0">
                                                                <span>Ans:</span> Program members can directly contact the Program Centre for the details and they may also check and track the status of their reward in the Inner Circle app.
                                                            </p>
                                                        </AccordionTab>
                                                    </Accordion>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
        </>
    )
}
