import React, { useState, useEffect } from 'react'
import Header from '../../common/Header'
import Footer from '../../common/Footer'
import logo_innercircle from '../../../assets/img/logo/logo-innercircle.svg'
import phone_icon from '../../../assets/img/phone-icon.svg'
import lock_icon from '../../../assets/img/lock-icon.svg'
import whitearrow from '../../../assets/img/whitearrow.svg'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import * as Constants from '../../../Constants/index'
import swal from 'sweetalert';
import bg from '../../../assets/img/innercirclebg.png'
import { Link } from 'react-router-dom'
export default function Login() {
	const [mobile, setMobile] = useState('')
	const [password, setPassword] = useState('')
	const [showPassword, setShowPassword] = useState(false)
	const [errors, setErrors] = useState({});
	const navigate = useNavigate();

	useEffect(() => {
		const userType = localStorage.getItem('user_type');
		if (userType === 'SALES') {
			navigate('/dashboard')
		} else if (userType === 'HO') {
			navigate('/dashboard')
		} else if (userType === 'RO') {
			navigate('/dashboard')
		} else if (userType === 'ADMIN') {
			navigate('/dashboard')
		}
	}, [])
	const toggleShowPassword = () => {
		if (showPassword === false) {
			setShowPassword(true)
		} else {
			setShowPassword(false)
		}
	}
	const validateForm = () => {
		let errors = {};

		if (!mobile) {
			errors.mobile = 'User ID is required';
		} else if (!/^\d+$/.test(mobile)) {
			errors.mobile = 'User ID must be numeric';
		} else if (mobile.length !== 10) {
			errors.mobile = 'User ID must be 10 digits';
		}

		if (!password) {
			errors.password = 'Password is required';
		}

		return errors;
	};

	const handleLogin = async () => {
		setErrors({});

		const validationErrors = validateForm();

		if (Object.keys(validationErrors).length > 0) {
			setErrors(validationErrors);
			return; // Don't proceed with the API call if there are validation errors
		}
		try {
			await axios.post(Constants.baseUrl + 'auth/login-password',
				{
					mobile: mobile,
					password: password,
				},
				{
					headers: {
						'Accept-Language': localStorage.getItem('language'),
						appVersion: Constants.av,
						platform: Constants.pt,
						company: Constants.company,
						program: Constants.program,
					},
				}
			)
				.then((res) => {
					if (res.status === 200) {
						if (res.data.code === 10001) {
							if (res.data.output.user.userType === 'SALES') {
								localStorage.setItem('user_id', res.data.output.user.id);
								localStorage.setItem('user_type', res.data.output.user.userType);
								localStorage.setItem('user_name', res.data.output.user.name);
								localStorage.setItem('user_email', res.data.output.user.email);
								localStorage.setItem('user_mobile', res.data.output.user.mobile);
								localStorage.setItem('token', res.data.output.access.token);
								localStorage.setItem('userUniq_id', res.data.output.user.userUniqid);
								navigate('/dashboard')
							} else if (res.data.output.user.userType === 'HO') {
								localStorage.setItem('user_id', res.data.output.user.id);
								localStorage.setItem('user_type', res.data.output.user.userType);
								localStorage.setItem('user_name', res.data.output.user.name);
								localStorage.setItem('user_email', res.data.output.user.email);
								localStorage.setItem('user_mobile', res.data.output.user.mobile);
								localStorage.setItem('token', res.data.output.access.token);
								localStorage.setItem('userUniq_id', res.data.output.user.userUniqid);
								navigate('/dashboard')
							} else if (res.data.output.user.userType === 'RO') {
								localStorage.setItem('user_id', res.data.output.user.id);
								localStorage.setItem('user_type', res.data.output.user.userType);
								localStorage.setItem('user_name', res.data.output.user.name);
								localStorage.setItem('user_email', res.data.output.user.email);
								localStorage.setItem('user_mobile', res.data.output.user.mobile);
								localStorage.setItem('token', res.data.output.access.token);
								localStorage.setItem('userUniq_id', res.data.output.user.userUniqid);
								navigate('/dashboard')
							} else if (res.data.output.user.userType === 'RM') {
								localStorage.setItem('user_id', res.data.output.user.id);
								localStorage.setItem('user_type', res.data.output.user.userType);
								localStorage.setItem('user_name', res.data.output.user.name);
								localStorage.setItem('user_email', res.data.output.user?.email);
								localStorage.setItem('user_mobile', res.data.output.user?.mobile);
								localStorage.setItem('token', res.data.output.access.token);
								localStorage.setItem('userUniq_id', res.data.output.user?.userUniqid);
								navigate('/dashboard')
							} else if (res.data.output.user.userType === 'ADMIN') {
								localStorage.setItem('user_id', res.data.output.user.id);
								localStorage.setItem('user_type', res.data.output.user.userType);
								localStorage.setItem('user_name', res.data.output.user.name);
								localStorage.setItem('user_email', res.data.output.user.email);
								localStorage.setItem('user_mobile', res.data.output.user.mobile);
								localStorage.setItem('token', res.data.output.access.token);
								// localStorage.setItem('userUniq_id', res.data.output.user.userUniqid);
								navigate('/dashboard')
							}
							else {
								swal({
									text: 'You are not a Sales Team Member',
									icon: 'error',
									buttons: false,
									timer: 2000
								});
							}
						} else if (res.data.code === 16001) {
							swal({
								text: res.data.msg,
								buttons: false,
								timer: 2000,
							});
						} else {
							swal({
								text: res.data.msg,
								buttons: false,
								timer: 2000,
							});
						}
					} else {
						swal({
							text: res.data.msg,
							buttons: false,
							timer: 2000,
						});
					}
				});

		} catch (error) {
			console.log(error);
		}
	}
	const handleKeyDown = (event) => {
		if (event.key === 'Enter') {
			handleLogin();
		}
	};
	return (
		<>
			<Header />
			<section className="main-page mt134">
				<div className="container">
					<div className="slider-part" style={{ backgroundImage: `url(${bg})`, }}>
						<div className="row">
							<div className="col-lg-6 col-md-6">
								<div className="logopage">
									<img src={logo_innercircle} className="img-responsive" alt="spl_logo" />
								</div>
							</div>
							<div className="col-lg-6 col-md-6">
								<div className="loginbox">
									<div className="row">
										<div className="col-md-12">
											<h4>
												Welcome! <span>Please login to continue.</span>
											</h4>
										</div>
									</div>
									<div className="row">
										<div className="col-md-12 col-xs-12">
											<div className="form-group">
												<img className="icn" src={phone_icon} />
												<input type="text" value={mobile} maxLength="10" onChange={(event) => {
													const input = event.target.value;
													if (/^\d*$/.test(input)) {
														setMobile(input);
													}
												}} placeholder="User ID" className="form-control frm" onKeyDown={handleKeyDown} />
											</div>
											{errors.mobile && (
												<div className="error-message-login">{errors.mobile}</div>
											)}
										</div>
										<div className="col-md-12 col-xs-12 btm24">
											<div className="form-group">
												<img className="icn" src={lock_icon} alt='' />
												<span className="showpass" title="Show Password" onClick={toggleShowPassword}>
													{showPassword === false ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}</span>
												<input type={showPassword === true ? "text" : "password"} id="myInput" value={password} onChange={(event) => setPassword(event.target.value)} placeholder="Enter Password" className="form-control frm" onKeyDown={handleKeyDown} />
											</div>
											{errors.password && (
												<div className="error-message-login">{errors.password}</div>
											)}
										</div>
										<div className="col-md-12 col-xs-12">
											<button type="submit" name="button" className="btn login_buttn" onClick={handleLogin}>Login <img src={whitearrow} alt='' /></button>

											<div className="frgt"><Link to="/forgot">Forgot Password? </Link></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section >
			<Footer />
		</>
	)
}
