import React from 'react'
import logo2 from '../../assets/img/logo/logo2.png'
import home from '../../assets/img/home.svg'
import aboutprogram from '../../assets/img/aboutprogram.svg'
import contact from '../../assets/img/contact.svg'
// import logo_mobile from '../../assets/img/logo/logo-mobile.svg'
import innercircle1 from '../../assets/img/logo/logo-innercircle1.svg'
import retailer from '../../assets/img/retailer.png'
import registration from '../../assets/img/registration.png'
import { Menubar } from "primereact/menubar";
import { Link } from 'react-router-dom'
export default function Header() {
  const userType = localStorage.getItem('user_type')
  const handleLogout = () => {
    localStorage.clear();
  };

  const items = [
    {
      label: "Home",
      url: "/",
    },
    {
      label: "About",
      url: "/about",
    },
    {
      label: " Contact Us",
      url: "/contact",
    },
  ];

  const itemsLogo = (
    <img alt="logo" src={logo2} height="40" className="mr-2"></img>
  );

  const sales = [
    {
      label: "Performance",
      url: "/dashboard",
    },
    {
      label: " Reports",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Enrollment report",
          url: "/enrollment_report",
        },
        {
          label: "Redemption Report",
          url: "/redemption_report",
        },
      ]
    }
  ];
  const admin = [
    {
      label: "Performance",
      url: "/dashboard",
    },
    {
      label: "Registration",
      url: "/registration",
    },
    {
      label: " Reports",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Enrollment report",
          url: "/enrollment_report",
        },
        {
          label: "Redemption Report",
          url: "/redemption_report",
        },
      ]
    }
  ];

  const salesLogo = (
    <img alt="logo" src={innercircle1} height="40" className="mr-2"></img>
  );
  return (
    <>
      <div className="wrapper home-2">
        <header>
          <div className="header-container header-sticky sticky">
            <div className="header-top-area">
              <div className="container">
                <div className="row">
                  <div className="col-md-6 col-sm-6 col-xs-12 text-left">
                    <div className="header-top-menu">
                      <ul className="timing">
                        <li className=""><Link to="mailto:info@jkpinnercircle.com"><i className="fa fa-envelope"></i> info@jkpinnercircle.com </Link></li>
                        <li><Link><i className="fa fa-clock-o"></i> Mon - Fri 09.30 AM - 5.30 PM </Link></li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-6 col-xs-12 text-right">
                    <div className="header-top-menu">
                      <ul className="timing">
                        <li className="support hidden-xs"><Link to="#"><i className="fa fa-phone"></i> 0124 4830989 </Link> </li>
                        {userType ? <li className="support"><span><i className="fa fa-user"></i> {localStorage.getItem('user_name')}<span className="name_text"> </span></span></li> : ''}
                        {userType ? <li className="account"><Link to="/" className="login navigator" onClick={handleLogout}><i className="fa fa-sign-in"></i> Logout </Link></li> : ""}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="header-middel-area hidden-xs">
              <div className="container">
                <div className="row">
                  <div className="col-md-2 col-sm-2 col-xs-6">
                    <div className={!userType ? 'logo logo1' : 'logo_inner_circle logo1'}>
                      {!userType ?
                        <Link to="/"><img src={logo2} alt="" /></Link> : ""}
                      {userType ? <Link to="/dashboard"><img src={innercircle1} alt="" /></Link> : ''}
                    </div>
                  </div>

                  <div className={userType ? "col-md-8 col-sm-8 col-xs-12 hidden-xs" : "col-md-10 col-sm-10 col-xs-12 hidden-xs"}>
                    <div className="main-menu-area">
                      <nav>
                        {!userType ?
                          <ul className="main-menu">
                            <li>
                              <Link to="/"><img src={home} alt='' /> Home</Link>
                            </li>
                            <li>
                              <Link to="/about"><img src={aboutprogram} alt='' /> About Program</Link>
                            </li>
                            <li>
                              <Link to="/contact"><img src={contact} alt='' /> Contact Us</Link>
                            </li>
                          </ul> : ''}
                        {userType === 'SALES' || userType === 'HO' || userType === 'RO' || userType === 'RM' ?
                          <div className="main-menu-area">
                            <nav>
                              <ul className="main-menu">
                                <li><Link to="/dashboard"> <img src={home} alt='' /> Performance</Link></li>
                                <li>
                                  <Link to=""><img src={retailer} alt='' /> Reports <i className="fa fa-angle-down"></i></Link>
                                  <ul className="dropdown">
                                    <li><Link to="/enrollment_report"> Enrollment report </Link></li>
                                    <li><Link to="/redemption_report"> Redemption Report</Link></li>
                                  </ul>
                                </li>
                              </ul>
                            </nav>
                          </div> : ''
                        }
                        {userType === 'ADMIN' ?
                          <div className="main-menu-area">
                            <nav>
                              <ul className="main-menu">
                                <li><Link to="/dashboard"> <img src={home} alt='' /> Performance</Link></li>
                                <li><Link to="/registration"> <img src={registration} alt='' /> Registration</Link></li>
                                <li>
                                  <Link to=""><img src={retailer} alt='' /> Reports <i className="fa fa-angle-down"></i></Link>
                                  <ul className="dropdown">
                                    <li><Link to="/enrollment_report"> Enrollment report </Link></li>
                                    <li><Link to="/redemption_report"> Redemption Report</Link></li>
                                  </ul>
                                </li>
                              </ul>
                            </nav>
                          </div> : ''
                        }
                      </nav>
                    </div>
                  </div>
                  {userType ?
                    <div className="col-md-2">
                      <div className="logo logo1">
                        <img src={logo2} alt="" />
                      </div>
                    </div> : ''}
                </div>
              </div>
            </div>

            <div className="mobile-menu-area hidden-sm hidden-md hidden-lg">
              <div className="container">
                <div className="row">
                  {/* <div className="mobile_logo">
                    {!userType ?
                      <Link to="/"><img src={logo2} alt="" /></Link> : ""}
                    {userType ? <Link to="/dashboard"><img src={innercircle1} alt="" /></Link> : ''}
                  </div> */}
                  <div className="col-xs-12">
                    <div className="mobile-menus">
                      <nav style={{ display: 'block' }}>
                        <ul className="main-menus">
                          {!userType ? (
                            <ul className="main-menus">
                              <div className="cards">
                                <Menubar model={items} start={itemsLogo} end={""} />
                              </div>
                            </ul>
                          ) : (
                            ""
                          )}
                          {userType === 'SALES' || userType === 'HO' ? (
                            <ul className="main-menus">
                              <div className="cards">
                                <Menubar model={sales} start={salesLogo} end={""} />
                              </div>
                            </ul>
                          ) : (
                            ""
                          )}
                          {userType === 'ADMIN' ? (
                            <ul className="main-menus">
                              <div className="cards">
                                <Menubar model={admin} start={salesLogo} end={""} />
                              </div>
                            </ul>
                          ) : (
                            ""
                          )}

                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
    </>
  )
}
