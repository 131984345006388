import React, { useState, useEffect } from "react";
import * as Constants from "../../Constants/index";
import axios from "axios";
import Header from "../common/Header";
import Footer from "../common/Footer";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment/moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
export default function Registration() {
  const navigate = useNavigate();
  // sales team details
  const [salesMobile, setSalesMobile] = useState("");
  const [salesSuccess, setSalesSuccess] = useState("");
  const [disable, setDisable] = useState(false);
  const [stateFetchOwner, setStateFetchOwner] = useState([]);
  const [districtFetchOwner, setDistrictFetchOwner] = useState([]);
  const [cityFetchOwner, setCityFetchOwner] = useState([]);
  const [stateFetchPro, setStateFetchPro] = useState([]);
  const [districtFetchPro, setDistrictFetchPro] = useState([]);
  const [cityFetchPro, setCityFetchPro] = useState([]);
  const [errors, setErrors] = useState({});
  const [panStatus, setPanStatus] = useState("error");
	const [verifyGstStatus, setVerifyGstStatus] = useState("error");

  //sales details
  const [salesName, setSalesName] = useState("");
  const [salesEmail, setSalesEmail] = useState("");
  const [salesZone, setSalesZone] = useState("");
  const [salesState, setSalesState] = useState("");
  const [salesCity, setSalesCity] = useState("");

  //owner details
  const [ownerName, setOwnerName] = useState("");
  const [firmName, setFirmName] = useState("");
  const [ownerMobile, setOwnerMobile] = useState("");
  const [ownerEmail, setOwnerEmail] = useState("");
  const [ownerSapCode, setOwnerSapCode] = useState("");
  const [ownerdob, setOwnerdob] = useState("");
  const [ownerAddress1, setOwnerAddress1] = useState("");
  const [ownerAddress2, setOwnerAddress2] = useState("");
  const [ownerAddress3, setOwnerAddress3] = useState("");
  const [ownerState, setOwnerState] = useState("");
  const [ownerStateId, setOwnerStateId] = useState(0);
  const [ownerDistrict, setOwnerDistrict] = useState("");
  const [ownerDistrictId, setOwnerDistrictId] = useState(0);
  const [ownerCity, setOwnerCity] = useState("");
  const [ownerCityId, setOwnerCityId] = useState(0);
  const [ownerPincode, setOwnerPincode] = useState("");
  const [uniqueId, setUniqueId] = useState("");

  //professional details
  const [proAddress1, setProAddress1] = useState("");
  const [proAddress2, setProAddress2] = useState("");
  const [proAddress3, setProAddress3] = useState("");
  const [proState, setProState] = useState("");
  const [proStateId, setProStateId] = useState(0);
  const [proDistrict, setProDistrict] = useState("");
  const [proDistrictId, setProDistrictId] = useState(0);
  const [proCity, setProCity] = useState("");
  const [proCityId, setProCityId] = useState(0);
  const [proPincode, setProPincode] = useState("");
  const [proMobile, setProMobile] = useState("");
  const [proEmail, setProEmail] = useState("");
  const [proGst, setProGst] = useState("");
  const [proPan, setProPan] = useState("");
  const [proSpocName, setProSpocName] = useState("");
  const [proSpocEmail, setProSpocEmail] = useState("");
  const [proSpocMobile, setProSpocMobile] = useState("");

  // /Sister Concern Details
  const [sapCode1, setSapCode1] = useState("");
  const [sapCode2, setSapCode2] = useState("");
  const [sapCode3, setSapCode3] = useState("");
  const [sapCode4, setSapCode4] = useState("");
  const [sapCode5, setSapCode5] = useState("");
  const [sapCode6, setSapCode6] = useState("");
  const [sapCode7, setSapCode7] = useState("");
  const [sapCode8, setSapCode8] = useState("");
  const [sapCode9, setSapCode9] = useState("");

  const formattedDob = moment(ownerdob).format("DD-MM-YYYY");

  // Calculate the exact date 18 years ago from today
  const exactMinDate = new Date();
  exactMinDate.setFullYear(exactMinDate.getFullYear() - 18);
  exactMinDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    // call state api for get state name
    let token = localStorage.getItem("token");

    try {
      axios
        .get(Constants.baseUrl + `master/geography/state?limit=1000`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 201 && res.data.code === 10001) {
            setStateFetchOwner(res.data.output.results);
            setStateFetchPro(res.data.output.results);
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  const salesValidate = async (mobile) => {
    let token = localStorage.getItem("token");
    try {
      await axios
        .post(
          Constants.baseUrl + "user/salesValidate",
          {
            parentMobile: mobile,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setSalesSuccess("sucess");
              setSalesName(res.data.output?.name);
              setSalesMobile(res.data.output?.mobile);
              setSalesEmail(res.data.output?.email);
              setSalesState(res.data.output?.addressWork?.workState);
              setSalesCity(res.data.output?.addressWork?.workCity);
              setSalesZone(res.data.output?.zone);
              swal({
                text: res.data.msg,
                // text: "Please Enter Otp Sent on Plumber's Mobile",
                icon: "success",
                timer: 2000,
                buttons: false,
              });
              setDisable(true);
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      salesValidate(salesMobile);
    }
  };

  // call state api by state ID for get owner district name
  const handleStateChangeOwner = (event) => {
    const selectedId = event.target.value;
    setDistrictFetchOwner([]);
    setCityFetchOwner([]);
    setOwnerDistrict("");
    setOwnerCity("");
    setOwnerPincode("");
    if (selectedId === "0") {
    } else {
      districtDataOwner(selectedId);
    }
    // districtDataOwner(selectedId);
  };

  let token = localStorage.getItem("token");
  const districtDataOwner = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setOwnerState(res.data.output.name);
              setOwnerStateId(res.data.output.id);
              setDistrictFetchOwner(res.data.output.districts);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call district api by district id for get permanent city name
  const handleDistrictChangeOwner = async (event) => {
    const selectedId = event.target.value;
    setCityFetchOwner([]);
    setOwnerCity("");
    setOwnerPincode("");
    if (selectedId === "0") {
    } else {
      cityDataOwner(selectedId);
    }
    // cityDataOwner(selectedId);
  };

  // const selectdistrictPerRef = useRef(null);
  const cityDataOwner = async (cityid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/district/${cityid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setOwnerDistrict(res.data.output.name);
              setOwnerDistrictId(res.data.output.id);
              setCityFetchOwner(res.data.output.cities);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call state api by state ID for get delivery district name
  const handleStateChangePro = async (event) => {
    const selectedId = event.target.value;
    setDistrictFetchPro([]);
    setCityFetchPro([]);
    setProDistrict("");
    setProCity("");
    setProPincode("");
    if (selectedId === "0") {
      // alert('hello')
    } else {
      districtDataPro(selectedId);
    }
  };

  const districtDataPro = async (stateid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/state/${stateid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setProState(res.data.output.name);
              setProStateId(res.data.output.id);
              setDistrictFetchPro(res.data.output.districts);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // call district api by district id for get delivery city name
  const handleDistrictChangePro = async (event) => {
    const selectedId = event.target.value;
    setCityFetchPro([]);
    setProCity("");
    setProPincode("");
    if (selectedId === "0") {
      // alert('hello')
    } else {
      cityDataPro(selectedId);
    }
  };

  const validatePan = () => {
    let errors = {};
    if (!proPan) {
      errors.proPan = "PAN no. required";
    } else if (proPan) {
      if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(proPan)) {
        errors.proPan = "Invalid PAN format";
      }
    }

    return errors;
  };

  // call pan verify api
  const verifyPAN = async (panNumber) => {
    setErrors({});
    const validationErrors = validatePan();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    try {
      await axios
        .post(
          // Constants.baseUrl + "verify/pan",
					Constants.baseUrl + "verify/complaince",
          {
            pan: panNumber,
          },
          {
            headers: {
              appVersion: Constants.av,
              platform: Constants.pt,
              "Accept-Language": localStorage.getItem("langauge"),
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setPanStatus(res.data.result);
              swal({
                text: res.data.msg,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
            } else if (res.data.code === 12002) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

	// call gst verify api
  const verifyGst = async (gstNumber) => {
    if (gstNumber === "") {
      swal({
        text: "Please enter gst no.",
        // icon: 'success',
        timer: 1000,
        buttons: false,
      });
      return;
    }
    try {
      await axios
        .post(
          Constants.baseUrl + "verify/gstIn",
          {
            gstNumber: gstNumber,
            filing_status_get: true,
          },
          {
            headers: {
              appVersion: Constants.av,
              platform: Constants.pt,
              "Accept-Language": localStorage.getItem("langauge"),
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              setVerifyGstStatus(res.data.result);
              swal({
                text: res.data.msg,
                icon: "success",
                timer: 2000,
                buttons: false,
              });
            } else if (res.data.code === 12002) {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                // icon: 'error',
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              // icon: 'error',
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  // registration form validation
  const validateForm = () => {
    let errors = {};

    if (!ownerName) {
      errors.ownerName = "Name required";
    } else if (ownerName.length < 3) {
      errors.ownerName = "Name must be at least 3 characters";
    }
    if (!firmName) {
      errors.firmName = "Firm name required";
    }
    if (!ownerMobile) {
      errors.ownerMobile = "Mobile no. required";
    } else if (ownerMobile.length !== 10) {
      errors.ownerMobile = "Mobile no. must be 10 digits";
    } else if (!/^[6-9]\d{9}$/.test(ownerMobile)) {
      errors.ownerMobile = "Invalid mobile no.";
    }
    if (ownerEmail && !/\S+@\S+\.\S+/.test(ownerEmail)) {
      errors.ownerEmail = "Invalid email address";
    }
    if (!ownerSapCode) {
      errors.ownerSapCode = "SAP code required";
    }
    // if (!ownerdob) {
    //     errors.ownerdob = 'DOB required';
    // }
    // if (!ownerAddress1) {
    //     errors.ownerAddress1 = 'Address required';
    // }
    // if (!ownerAddress2) {
    //     errors.ownerAddress2 = 'Address required';
    // }
    // if (!ownerAddress3) {
    //     errors.ownerAddress3 = 'Landmark required';
    // }
    // if (!ownerState) {
    //     errors.ownerState = 'State required';
    // }
    // if (!ownerDistrict) {
    //     errors.ownerDistrict = 'District required';
    // }
    // if (!ownerCity) {
    //     errors.ownerCity = 'City required';
    // }
    // if (!ownerPincode) {
    //     errors.ownerPincode = 'Pincode required';
    // }
    if (!uniqueId) {
      errors.uniqueId = "Unique id required";
    } else if (uniqueId.length !== 7) {
      errors.uniqueId = "Unique id must be 7 digits";
    }
    // if (!proAddress1) {
    //     errors.proAddress1 = 'Address required';
    // }
    // if (!proAddress2) {
    //     errors.proAddress2 = 'Address required';
    // }
    // if (!proAddress3) {
    //     errors.proAddress3 = 'Landmark required';
    // }
    // if (!proState) {
    //     errors.proState = 'State required';
    // }
    // if (!proDistrict) {
    //     errors.proDistrict = 'District required';
    // }
    // if (!proCity) {
    //     errors.proCity = 'City required';
    // }
    // if (!proPincode) {
    //     errors.proPincode = 'Pincode required';
    // }
    if (proMobile) {
      if (proMobile.length !== 10) {
        errors.proMobile = "Mobile no. must be 10 digits";
      }
    }
    if (proEmail && !/\S+@\S+\.\S+/.test(proEmail)) {
      errors.proEmail = "Invalid email address";
    }
    if (!proGst) {
      errors.proGst = "GST no. required";
    } else if (proGst.length !== 15) {
      errors.proGst = "GST no. must be 15 digits";
    }
    if (!proPan) {
      errors.proPan = "PAN no. required";
    } else if (proPan) {
      if (!/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/i.test(proPan)) {
        errors.proPan = "Invalid PAN format";
      }
    }
    if (proSpocName) {
      if (proSpocName.length < 3) {
        errors.proSpocName = "Name must be at least 3 characters";
      }
    }
    if (proSpocEmail && !/\S+@\S+\.\S+/.test(proSpocEmail)) {
      errors.proSpocEmail = "Invalid email address";
    }
    if (proSpocMobile) {
      if (proSpocMobile.length !== 10) {
        errors.proSpocMobile = "Mobile no. must be 10 digits";
      } else if (!/^[6-9]\d{9}$/.test(proSpocMobile)) {
        errors.proSpocMobile = "Invalid mobile no.";
      }
    }
    return errors;
  };

  //call register api for plumber registration
  const register = async (event) => {
    let token = localStorage.getItem("token");
    event.preventDefault();
    setErrors({});
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return; // Don't proceed with the API call if there are validation errors
    }
    if (panStatus === "error") {
      swal({
        text: "Please Verify Pan Number",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
      return;
    }

		if (verifyGstStatus === "error") {
      swal({
        text: "Please Verify Gst Number",
        icon: "error",
        timer: 2000,
        buttons: false,
      });
      return;
    }

    try {
      await axios
        .post(
          Constants.baseUrl + "user/manage",
          {
            name: ownerName,
            email: ownerEmail,
            mobile: ownerMobile,
            alternateMobile: proMobile,
            whatsapMobile: "",
            gst: proGst,
            gender: "",
            userUniqid: uniqueId,
            dob: (ownerdob != "") ? moment(ownerdob).format('YYYY-MM-DDTHH:mm:ss.SSSZ') : "",
            password: "",
            status: "APPROVED",
            userModelKYC: {
              aadharMobile: "",
              aadharNumber: "",
              aadharFrontUrl: "",
              aadharBackUrl: "",
              aadharVerify: 0,
              aadharVerifiedBy: "",
              aadharVerifiedOn: "",
              panNumber: proPan,
              panFrontUrl: "",
              panVerify: 0,
              panVerifiedBy: "",
              panVerifiedOn: "",
            },
            addressWork: {
              workAddress1: proAddress1,
              workAddress2: proAddress2,
              workAddress3: proAddress3,
              workCity: proCity,
              workCityId: proCityId,
              workState: proState,
              workStateId: proStateId,
              workdistrict: proDistrict,
              workdistrictId: proDistrictId,
              workPincode: proPincode,
              workCountry: "",
            },
            addressModelPerm: {
              permAddress1: ownerAddress1,
              permAddress2: ownerAddress2,
              permAddress3: ownerAddress3,
              permCity: ownerCity,
              permCityId: ownerCityId,
              permState: ownerState,
              permStateId: ownerStateId,
              permdistrict: ownerDistrict,
              permdistrictId: ownerDistrictId,
              permPincode: ownerPincode,
              permCountry: "",
            },
            sisterConcern: {
              sister1: sapCode1,
              sister2: sapCode2,
              sister3: sapCode3,
              sister4: sapCode4,
              sister5: sapCode5,
              sister6: sapCode6,
              sister7: sapCode7,
              sister8: sapCode8,
              sister9: sapCode9,
            },
            bankDetail: {
              bankAccountNo: "",
              accountType: "",
              ifsc: "",
              beneficiary: "",
              bankName: "",
              holderName: "",
              branchName: "",
              cancelChk: "",
              upiId: "",
              upiIdScreen: "",
            },
            parentId: 0,
            parentType: "SALES",
            userType: "MEMBER",
            identityType: "",
            identityNumber: "",
            identityCardBackUrl: "",
            identityCardFrontUrl: "",
            getsStateId: "",
            getsDistrictId: "",
            getsCityId: "",
            retailerFirmName: "",
            firmName: firmName,
            firmSapCode: ownerSapCode,
            spocName: proSpocName,
            spocMobile: proSpocMobile,
            spocEmail: proSpocEmail,
            zone: "",
            parentName: salesName,
            parentMobile: salesMobile,
            parentEmail: salesEmail,
            parentZone: salesZone,
            parentState: salesState,
            parentCity: salesCity,
            officialemail: proEmail,
            officialMobile: proMobile,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Accept-Language": localStorage.getItem("langauge"),
              appVersion: Constants.av,
              platform: Constants.pt,
              company: Constants.company,
              program: Constants.program,
            },
          }
        )
        .then((res) => {
          if (res.status === 201) {
            if (res.data.code === 10001) {
              // setMobileOtp('')
              swal({
                text: "Registration Successfull",
                // text: "Please Enter Otp Sent on Plumber's Mobile",
                icon: "success",
                timer: 2000,
                buttons: false,
              });

              setTimeout(() => {
                // CancelRegisteration();
                window.location = "/registration";
              }, 2000);
            } else if (res.data.code === 12001) {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            } else {
              swal({
                text: res.data.msg,
                icon: "error",
                timer: 2000,
                buttons: false,
              });
            }
          } else {
            swal({
              text: res.data.msg,
              icon: "error",
              timer: 2000,
              buttons: false,
            });
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const cityDataPro = async (cityid) => {
    try {
      await axios
        .get(Constants.baseUrl + `master/geography/district/${cityid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Accept-Language": localStorage.getItem("langauge"),
          },
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.code === 10001) {
              setProDistrict(res.data.output.name);
              setProDistrictId(res.data.output.id);
              setCityFetchPro(res.data.output.cities);
            } else {
              // swal(res.data.msg)
            }
          } else {
            // swal(res.data.msg);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header />
      <section className="blog-area pt182">
        <div className="container">
          <div className="row">
            <div className="col-md-12 tab mb-20">
              <ul className="nav nav-tabs" role="tablist">
                <li role="presentation" className="active">
                  <Link to="" role="tab" data-toggle="tab">
                    Registration Form
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <div className="all-single-blog rpt m-b-1">
                <div className="row">
                  <div className="row clearfix mb-15 callwell">
                    <div className="col-md-3 col-sm-12 col-xs-12">
                      <input
                        className="frmcntrl"
                        placeholder="Mobile Number"
                        maxLength="10"
                        type="text"
                        value={salesMobile}
                        onChange={(event) => {
                          const input = event.target.value;
                          if (/^\d*$/.test(input)) {
                            setSalesMobile(input);
                          }
                        }}
                        disabled={disable}
                        onKeyDown={handleKeyDown}
                      />
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                      <button
                        type="button"
                        className="btn login_white_btn"
                        disabled={salesMobile.trim() === "" || disable === true}
                        onClick={() => salesValidate(salesMobile)}
                      >
                        {" "}
                        Find Sales{" "}
                      </button>
                    </div>
                  </div>
                  {salesSuccess === "sucess" ? (
                    <div>
                      <div className="col-md-12">
                        <h4 className="heading-panel">Owner's Details</h4>
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Owner Name* </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" Owner name"
                            type="text"
                            value={ownerName}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                setOwnerName(input);
                              }
                            }}
                          />
                        </div>
                        {errors.ownerName && (
                          <div className="error-message">
                            {errors.ownerName}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Firm Name* </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" Firm name"
                            type="text"
                            value={firmName}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                // Only alphabetic characters
                                setFirmName(input);
                              }
                            }}
                          />
                        </div>
                        {errors.firmName && (
                          <div className="error-message">{errors.firmName}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Mobile* </label>
                          <input
                            className="form-control"
                            maxLength="10"
                            placeholder=" Mobile"
                            type="text"
                            value={ownerMobile}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^\d*$/.test(input)) {
                                setOwnerMobile(input);
                              }
                            }}
                          />
                        </div>
                        {errors.ownerMobile && (
                          <div className="error-message">
                            {errors.ownerMobile}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Email </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" Email"
                            type="text"
                            value={ownerEmail}
                            onChange={(e) => setOwnerEmail(e.target.value)}
                          />
                        </div>
                        {errors.ownerEmail && (
                          <div className="error-message">
                            {errors.ownerEmail}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code of Firm* </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" SAP code of firm"
                            type="text"
                            value={ownerSapCode}
                            onChange={(e) => setOwnerSapCode(e.target.value)}
                          />
                        </div>
                        {errors.ownerSapCode && (
                          <div className="error-message">
                            {errors.ownerSapCode}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label className="dob-100">Date of Birth </label>
                          <DatePicker
                            selected={ownerdob}
                            onChange={(date) => setOwnerdob(date)}
                            dateFormat="dd-MM-yyyy "
                            className="form-control"
                            value={ownerdob}
                            showYearDropdown
                            showMonthDropdown
                            placeholderText="dd-mm-yyyy"
                            maxDate={exactMinDate} // Up to the exact 18th birthday
                            onKeyDown={(e) => e.preventDefault()} // Prevent manual input using keyboard
                            onFocus={(e) => e.target.blur()} // Blur the input to prevent focus
                            onClick={(e) => e.preventDefault()} // Preven
                          />
                        </div>
                        {errors.ownerdob && (
                          <div className="error-message">{errors.ownerdob}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>House / Flat / Block No. </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" House / Flat / Block No."
                            type="text"
                            value={ownerAddress1}
                            onChange={(e) => setOwnerAddress1(e.target.value)}
                          />
                        </div>
                        {errors.ownerAddress1 && (
                          <div className="error-message">
                            {errors.ownerAddress1}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Street / Colony / Locality Name </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" Street / Colony / Locality Name"
                            type="text"
                            value={ownerAddress2}
                            onChange={(e) => setOwnerAddress2(e.target.value)}
                          />
                        </div>
                        {errors.ownerAddress2 && (
                          <div className="error-message">
                            {errors.ownerAddress2}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Landmark </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" Landmark"
                            type="text"
                            value={ownerAddress3}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                setOwnerAddress3(input);
                              }
                            }}
                          />
                        </div>
                        {errors.ownerAddress3 && (
                          <div className="error-message">
                            {errors.ownerAddress3}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>State </label>
                          <select
                            className="form-control2"
                            onChange={handleStateChangeOwner}
                          >
                            <option value="0">Select State</option>
                            {stateFetchOwner.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.ownerState && (
                          <div className="error-message">
                            {errors.ownerState}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>District </label>
                          <select
                            className="form-control2"
                            onChange={handleDistrictChangeOwner}
                          >
                            <option value="0">Select District</option>
                            {districtFetchOwner.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.ownerDistrict && (
                          <div className="error-message">
                            {errors.ownerDistrict}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>City </label>
                          <select
                            className="form-control2"
                            value={ownerCity}
                            onChange={(event) => {
                              setOwnerCity(event.target.value);
                              // Step 2: Find and set the selected city's ID
                              const selectedOwnerCity = cityFetchOwner.find(
                                (item) => item.name === event.target.value
                              );
                              setOwnerCityId(
                                selectedOwnerCity ? selectedOwnerCity.id : ""
                              );
                            }}
                          >
                            <option value="">Select City</option>
                            {cityFetchOwner.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.ownerCity && (
                          <div className="error-message">
                            {errors.ownerCity}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Pin Code </label>
                          <input
                            className="form-control"
                            maxLength="6"
                            placeholder="Enter pin code"
                            type="text"
                            value={ownerPincode}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^\d*$/.test(input)) {
                                setOwnerPincode(input);
                              }
                            }}
                          />
                        </div>
                        {errors.ownerPincode && (
                          <div className="error-message">
                            {errors.ownerPincode}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>User Unique Id* </label>
                          <input
                            className="form-control"
                            maxLength="7"
                            placeholder=" User unique id"
                            type="text"
                            value={uniqueId}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^\d*$/.test(input)) {
                                setUniqueId(input);
                              }
                            }}
                          />
                        </div>
                        {errors.uniqueId && (
                          <div className="error-message">{errors.uniqueId}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {salesSuccess === "sucess" ? (
                    <div>
                      <div className="col-md-12">
                        <h4 className="heading-panel">Professional Details</h4>
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>House / Flat / Block No. </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder=" House / Flat / Block No."
                            type="text"
                            value={proAddress1}
                            onChange={(e) => setProAddress1(e.target.value)}
                          />
                        </div>
                        {errors.proAddress1 && (
                          <div className="error-message">
                            {errors.proAddress1}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Street / Colony / Locality Name </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="Street / Colony / Locality Name"
                            type="text"
                            value={proAddress2}
                            onChange={(e) => setProAddress2(e.target.value)}
                          />
                        </div>
                        {errors.proAddress2 && (
                          <div className="error-message">
                            {errors.proAddress2}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Landmark </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="Landmark"
                            type="text"
                            value={proAddress3}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                setProAddress3(input);
                              }
                            }}
                          />
                        </div>
                        {errors.proAddress3 && (
                          <div className="error-message">
                            {errors.proAddress3}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>State </label>
                          <select
                            className="form-control2"
                            onChange={handleStateChangePro}
                          >
                            <option value="0">Select State</option>
                            {stateFetchPro.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.proState && (
                          <div className="error-message">{errors.proState}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>District </label>
                          <select
                            className="form-control2"
                            onChange={handleDistrictChangePro}
                          >
                            <option value="0">Select District</option>
                            {districtFetchPro.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.proDistrict && (
                          <div className="error-message">
                            {errors.proDistrict}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>City </label>
                          <select
                            className="form-control2"
                            value={proCity}
                            onChange={(event) => {
                              setProCity(event.target.value);
                              // Step 2: Find and set the selected city's ID
                              const selectedProCity = cityFetchPro.find(
                                (item) => item.name === event.target.value
                              );
                              setProCityId(
                                selectedProCity ? selectedProCity.id : ""
                              );
                            }}
                          >
                            <option value="">Select City</option>
                            {cityFetchPro.map((item, index) => {
                              return (
                                <>
                                  <option key={index} value={item.name}>
                                    {item.name}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                        {errors.proCity && (
                          <div className="error-message">{errors.proCity}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Pin Code </label>
                          <input
                            className="form-control"
                            maxLength="6"
                            placeholder="Pin code"
                            type="text"
                            value={proPincode}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^\d*$/.test(input)) {
                                setProPincode(input);
                              }
                            }}
                          />
                        </div>
                        {errors.proPincode && (
                          <div className="error-message">
                            {errors.proPincode}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Mobile</label>
                          <input
                            className="form-control"
                            maxLength="10"
                            placeholder="Mobile"
                            type="text"
                            value={proMobile}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^\d*$/.test(input)) {
                                setProMobile(input);
                              }
                            }}
                          />
                        </div>
                        {errors.proMobile && (
                          <div className="error-message">
                            {errors.proMobile}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>Official E-Mail ID </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="Official email id"
                            type="text"
                            value={proEmail}
                            onChange={(e) => setProEmail(e.target.value)}
                          />
                        </div>
                        {errors.proEmail && (
                          <div className="error-message">{errors.proEmail}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>GST No.* </label>
													{verifyGstStatus === "success" ? (
                            <span className="verified-pan">Verified</span>
                          ) : (
                            <span
                              className="verify-pan"
                              onClick={() =>
                                verifyGst(proGst)
                              }
                            >
                              Verify
                            </span>
                          )}
                          <input
                            className="form-control"
                            maxLength="15"
                            placeholder="GST no."
                            type="text"
                            value={proGst}
                            onChange={(e) => {
                              const cleanedValue = e.target.value.replace(
                                /[^a-zA-Z0-9]/g,
                                ""
                              );
                              setProGst(cleanedValue.toUpperCase());
                            }}
                          />
                        </div>
                        {errors.proGst && (
                          <div className="error-message">{errors.proGst}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>PAN No.* </label>
                          {panStatus === "success" ? (
                            <span className="verified-pan">Verified</span>
                          ) : (
                            <span
                              className="verify-pan"
                              onClick={() => verifyPAN(proPan)}
                            >
                              Verify
                            </span>
                          )}
                          <input
                            className="form-control"
                            minLength="10"
                            maxLength="10"
                            placeholder="PAN no."
                            type="text"
                            value={proPan}
                            onChange={(e) => {
                              const cleanedValue = e.target.value.replace(
                                /[^a-zA-Z0-9]/g,
                                ""
                              );
                              setProPan(cleanedValue.toUpperCase());
                            }}
                            disabled={panStatus === "success"}
                          />
                        </div>
                        {errors.proPan && (
                          <div className="error-message">{errors.proPan}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SPOC Name </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SPOC name"
                            type="text"
                            value={proSpocName}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^[a-zA-Z\s]*$/.test(input)) {
                                // Only alphabetic characters
                                setProSpocName(input);
                              }
                            }}
                          />
                        </div>
                        {errors.proSpocName && (
                          <div className="error-message">
                            {errors.proSpocName}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SPOC Official E-Mail ID </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SPOC official email id"
                            type="text"
                            value={proSpocEmail}
                            onChange={(e) => setProSpocEmail(e.target.value)}
                          />
                        </div>
                        {errors.proSpocEmail && (
                          <div className="error-message">
                            {errors.proSpocEmail}
                          </div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SPOC Mobile </label>
                          <input
                            className="form-control"
                            maxLength="10"
                            placeholder="Mobile"
                            type="text"
                            value={proSpocMobile}
                            onChange={(event) => {
                              const input = event.target.value;
                              if (/^\d*$/.test(input)) {
                                setProSpocMobile(input);
                              }
                            }}
                          />
                        </div>
                        {errors.proSpocMobile && (
                          <div className="error-message">
                            {errors.proSpocMobile}
                          </div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {salesSuccess === "sucess" ? (
                    <div>
                      <div className="col-md-12">
                        <h4 className="heading-panel">
                          Sister Concern Details
                        </h4>
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 1 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode1}
                            onChange={(e) => setSapCode1(e.target.value)}
                          />
                        </div>
                        {errors.sapCode1 && (
                          <div className="error-message">{errors.sapCode1}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 2 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode2}
                            onChange={(e) => setSapCode2(e.target.value)}
                          />
                        </div>
                        {errors.sapCode2 && (
                          <div className="error-message">{errors.sapCode2}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 3 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode3}
                            onChange={(e) => setSapCode3(e.target.value)}
                          />
                        </div>
                        {errors.sapCode3 && (
                          <div className="error-message">{errors.sapCode3}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 4 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode4}
                            onChange={(e) => setSapCode4(e.target.value)}
                          />
                        </div>
                        {errors.sapCode4 && (
                          <div className="error-message">{errors.sapCode4}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 5 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode5}
                            onChange={(e) => setSapCode5(e.target.value)}
                          />
                        </div>
                        {errors.sapCode5 && (
                          <div className="error-message">{errors.sapCode5}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 6 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode6}
                            onChange={(e) => setSapCode6(e.target.value)}
                          />
                        </div>
                        {errors.sapCode6 && (
                          <div className="error-message">{errors.sapCode6}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 7 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode7}
                            onChange={(e) => setSapCode7(e.target.value)}
                          />
                        </div>
                        {errors.sapCode7 && (
                          <div className="error-message">{errors.sapCode7}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 8 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode8}
                            onChange={(e) => setSapCode8(e.target.value)}
                          />
                        </div>
                        {errors.sapCode8 && (
                          <div className="error-message">{errors.sapCode8}</div>
                        )}
                      </div>
                      <div className="col-md-3 col-sm-12 col-xs-12">
                        <div className="form-group has-float-label style-two">
                          <label>SAP Code 9 </label>
                          <input
                            className="form-control"
                            maxLength="35"
                            placeholder="SAP code"
                            type="text"
                            value={sapCode9}
                            onChange={(e) => setSapCode9(e.target.value)}
                          />
                        </div>
                        {errors.sapCode9 && (
                          <div className="error-message">{errors.sapCode9}</div>
                        )}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {salesSuccess === "sucess" ? (
                    <div className="col-md-12">
                      <div className="form-group text-center mt25">
                        <input
                          type="submit"
                          value="Submit"
                          className="btn login_btn"
                          onClick={register}
                        />

                        <input
                          type="submit"
                          value="Cancel"
                          className="btn resetbtn"
                          onClick={() => (window.location = "/registration")}
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
